import React from 'react'
import './index.scss'
import Countdown_, { zeroPad } from 'react-countdown'

const Completionist = () => <span>FridgeFinds is finished!</span>;

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <span className='countdown-text'>
        <div className='countdown-labels'>
          <p className='days'>{zeroPad(days)}</p>
          <p>Day(s)</p>
        </div> 
        <div className='countdown-separator'>:</div> 
        <div className='countdown-labels'>
          <p className='hours'>{zeroPad(hours)}</p>
          <p>Hour(s)</p>
        </div>        
        <div className='countdown-separator'>:</div> 
        <div className='countdown-labels'> 
          <p className='minutes'>{zeroPad(minutes)}</p>
          <p>Minute(s)</p>
        </div>
        <div className='countdown-separator'>:</div> 
        <div className='countdown-labels'>
          <p className='seconds'>{zeroPad(seconds)}</p>
          <p>Second(s)</p>
        </div>
      </span>
    )
  }
};

const Countdown = () => {
  return (
    <div className='countdown'>
        <h1 className='countdown-header'>Countdown to FridgeFinds</h1>
        <Countdown_ date={'2025-08-01T12:00:00'} renderer={renderer} />
    </div>
  )
}

export default Countdown