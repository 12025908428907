import React from 'react'
import Fridgefinds from '../../assets/fridgefinds.png'
import './index.scss';
import { Link } from 'react-router-dom';
import { FaCircleUser } from "react-icons/fa6";

const Navbar = () => {
  return (
    <header className='navbar'>
      <Link to='/' className='navbar-logo'>
        <img src={Fridgefinds} alt='Fridgefinds logo' />
      </Link>
      <Link to='/' className="navbar-title">
        <h1><b>FridgeFinds</b></h1>
      </Link>
      <nav className='navbar-links'>
        <Link className="navbar-sign-in" to='/'>Log In</Link>
        <Link className="navbar-sign-up" to='/'>Sign Up</Link>
      </nav>
      <nav className='navbar-links-mobile'>
        <Link className="navbar-sign-in" to='/'><FaCircleUser className='navbar-links-icon'/></Link>
      </nav>
    </header>

  )
}

export default Navbar