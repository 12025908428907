import React from 'react'
import MealPlan from '../../assets/MealPlan.webp'
import AutomatedGroceries from '../../assets/AutomatedGroceries.webp'
import Community from '../../assets/Community.webp'
import './index.scss'

const FeatureShowcase = () => {
  return (
    <div className='feature-showcase-container'>
        <div className='feature-showcase'>
            <div className='feature-showcase-content'>
                <img className='feature-showcase-image' src={MealPlan} alt='Meal Plan' />
                <h1 className='feature-showcase-title'>Efficient Meal Planning</h1>
                <p>Meal plan efficiently and assign cooks to collaborate with family or housemates, with ingredients being automatically reserved and added to your grocery list.</p>
            </div>
            <div className='feature-showcase-divider'></div>
            <div className='feature-showcase-content'>
                <img className='feature-showcase-image' src={AutomatedGroceries} alt='Automated Groceries' />
                <h1 className='feature-showcase-title'>Seamless Grocery Shopping</h1>
                <p>With grocery delivery automation and missing ingredients being automatically added to your basket, grocery shopping has never been easier.</p>
            </div>
            <div className='feature-showcase-divider'></div>
            <div className='feature-showcase-content'>
                <img className='feature-showcase-image' src={Community} alt='Community Engagement' />
                <h1 className='feature-showcase-title'>Community Engagement</h1>
                <p>View recipes and meal plans that have been shared by the community, as well as share your own! See your friends recipes easily in the community feed.</p>
            </div>
        </div>
    </div>
  )
}

export default FeatureShowcase