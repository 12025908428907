import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';

const Footer = () => {
    return (
        <div className='footer-container'>
            <div className='footer'>
                <div className='footer-links'>
                    <h1>Feel like reaching out?</h1>
                    <p>Contact us at <b>contact@fridgefinds.co.uk</b> or on any of our socials and a team member will get right back to you!</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;