import React from 'react'
import Hero from '../../components/Hero'
import Countdown from '../../components/Countdown'
import './index.scss'
import FeatureShowcase from '../../components/FeatureShowcase'

const Home = () => {
  return (
    <div className='home-container'>
      <Hero />
      <div className='home'>
        <div className='home-content'>
          <h1>Make cooking perfection</h1>
          <p>Streamline meal planning, grocery shopping, and cooking with ease!</p>
          <FeatureShowcase />
        </div>
      </div>
      <Countdown />
    </div>
  )
}

export default Home