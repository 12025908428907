import React from 'react'
import ConfettiExplosion from 'react-confetti-explosion'
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import './index.scss'

const Hero = () => {
    const [isExploding, setIsExploding] = React.useState(false)

    return (
        <div className='hero-container'>
            <div className='hero-left'>
                <h1>Your ultimate kitchen organiser</h1>
                <p>Store all your ingredients in one place</p>
                <p>Easily create and save your favourite recipes.</p>
                <p>Plan your favourite meals ahead of time.</p>
                <p>Find recipes based on current ingredients!</p>
                <div className='hero-button' >
                    {isExploding && <ConfettiExplosion className="explosion" width={1000}/>}
                    <h2 className='glowing-text' onMouseEnter={() => setIsExploding(true)} onMouseLeave={() => setIsExploding(false)}>Coming summer 2025!</h2>
                </div>
                <div className='hero-links'>
                    <a href='https://www.facebook.com/people/FridgeFinds/61559351511245/'><FaFacebook className='facebook' /></a>
                    <a href='https://twitter.com/FridgeFindsApp'><FaXTwitter /></a>
                    <a href='https://www.instagram.com/fridgefindsapp/'><FaInstagram /></a>
                </div>
            </div>
            <div class="custom-shape-divider-bottom-1714464732">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                </svg>
            </div>
            <div className='hero-right' />
        </div>
    )
}

export default Hero